/* -----------------最外層------------------- */

/* 修改 .leaderboard-container 樣式 */
.leaderboard-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  z-index: 1;
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden; /* 防止水平滚动 */
  overflow-y: scroll ; /* 允许垂直滚动 */
  scrollbar-width: none; /* Firefox */
  user-select:none;
}
.leaderboard-background{
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  position: absolute;
  padding: 0;
  margin: 0;
}

.Upper{
  z-index: 5;
}
.Lower{
  margin-top: 75vw;
  z-index: 0;
}
/* -----------------倒數計時------------------- */


.countdown-container {
  margin-bottom: 0;
  padding-bottom: 1vh;
  border-top: none;
  border-bottom: 0.4vh solid #000000;
  border-radius: 0 0 1.5vh 1.5vh; /* 只保留下半圆 */
  display: flex;
  flex-direction: column;
  background-color: #FFDD00;
  z-index: 10;
  box-shadow: 0px 1vh 0px rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;  
}

.countdown {
  display: flex;
  align-items: baseline;
}

.countdown span {
  margin: 0 1vw;
}

.time {
  font-size: 3vw;
  font-weight: bold;
}

.time-label {
  font-size: 2vw;
  color: #666;
}
/* -----------------LeaderBoard Header: 不同Tab------------------- */
.day button{
  color: #ffffff;
}

.night button{
  color: #000000;
}
.leaderboard-header {
  z-index: 6;
  background-color: transparent;
}


.leaderboard-Tab{
  background-color: transparent;
  height: 100%;
font-size: 3.5vw;
} 
 
.leaderboard-Tab button {
  background-color: transparent;
  align-items: center;
  text-align: center;
  margin: 40vw 6vw 8vw 6vw;
  border:#ffffffa2;
  width: 25vw;
  
}

.active-tab {
  animation: scaleText 0.5s infinite alternate;
}

@keyframes scaleText {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

/* -----------------LeaderBoard 最外層------------------- */
.leaderboard-list-container {
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
  padding: 2vw;
  z-index: 2;
  overflow-y: visible;
}

.leaderboard-list {
  list-style-type: none;
  padding: 0;
  z-index: 2;

}

/* -----------------LeaderBoard 不同Tier------------------- */
.leaderboard-list-Tier-container {
  margin-bottom: 5vw;
  padding: 3vw;
  border-radius: 5vw;
  border: 1vw solid #000000;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 1vw 1vw 0px rgb(0, 0, 0);
  backdrop-filter: blur(0.5vw)
  
}

.leaderboard-list-Tier-Header{
  width: 100%;
}

.leaderboard-item-container{
padding: 15vw 0 0 0;
}
/* ----------------- LeaderBoard : Reward ------------------- */
.leaderboard-list-reward-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
  margin: 0px;
}

/* ----------------- LeaderBoard : User ------------------- */

.leaderboard-item {
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 3vw;
  margin: 3vw 0 0 0;
  padding: 2vw 0px 2vw 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 0px rgb(0, 0, 0);
}
.Tier-Header{
position: absolute;
width: 90%;
transform: translate(-50%,0);
left: 50%;
top: 30px;
}
.highlight {
  transform: scale(1.05);
  background: rgba(255, 216, 41, 0.8);
}
.highlight-1 ::before{
  background: linear-gradient(15deg, #fad179,#ffe96b, #f9ffc1, #fff172, #ffe600);
}
.highlight-2 ::before{
  background: linear-gradient(15deg, #cccccc,#d4d4d4, #ffffff, #e0e0e0, #aaaaaa);
}
.highlight-3 ::before{
  background: linear-gradient(15deg, #8b6615,#ceae58, #f9ffc1, #ffe572, #ffc400);
}
.Tire2 ::before{
  background: linear-gradient(35deg, #fabbf2,#f1ccf5, #f9ffc1);
}
.highlight-outOfRank {
  margin: 2vw;
  background: rgba(255, 216, 41, 0.8);
}

.rank-container
{
  display: inline-block;
  position: absolute;
  top: -1vw;
  left: -1vw;
  height: 100%;
  width: 10vw;
  border-radius: 10px 0px 0px 10px;
  transform-origin: top left;
}

.rank-container::before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(206, 221, 178);
  height: 100%;
  width: 15vw;
  border-radius: 2vw 0px 2vw 2vw;
  transform-origin: top left;
  transform: scale(1, 1.6) perspective(4vw) rotateX(-10deg);
  box-shadow: 2vw 1.5vw 0px rgb(0, 0, 0);  
}
.rank {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-shadow: 0.5vw 0.5vw 0 rgba(0, 0, 0, 0.8);
  font-size: 6vw;
  font-weight: bold;
  font-style: italic;
  flex-grow: 0;
  text-align: center;
}

.user-avatar {
  width: auto;
  height: 50px;
  border-radius: 50%;
  margin-right: 2vw;
}
.information
{
  flex: 1;
  align-items: start;
  text-align: left;
  margin: 0 0 0 20vw;
}

.username {
  color: #000000;
  font-size:4vw;
  font-weight: bold;
}

.points {
  font-weight:300;
  font-size:3vw;
  color: #494949;
  display: flex;
  align-items: center;
}

.points.highlight {
  font-weight: bold;
  color: #ffffff;
}

.leaderboard-item.spacer {
  align-items: center;
  height: 10px;
  width: 10px;
  margin: auto; /* 添加此行使其居中 */
  margin-bottom: 10px;
  padding: 20px;
  justify-content: center;
}

