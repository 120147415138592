.profile-container {
  margin-bottom: 0px;
  padding: 1vh 2vh 1vh 2vh;
  border-top: none;
  border-bottom: 2px solid #000000;
  border-radius: 0 0 15px 15px; /* 只保留下半圆 */
  display: flex;
  flex-direction: column;
  background-color: #FFDD00;
  z-index: 501;
  box-shadow: 0px 5px 0px rgb(255, 255, 255);
  font-size: 2vh;
  user-select:none
}
.message {
  position: fixed;
  top: 15vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffcc00;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #000;
  z-index: 1000;
  width: 80%;
  text-align: center;
}

.profile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-character-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.total-score {
 
  font-weight: bold;
  display: flex;
  align-items: center;
}
.catz{
  width: 3vh;
  height: 3vh;
  filter: drop-shadow(0.5vh 0.5vh 0px rgba(0, 0, 0, 0.5));
}

.user-infoc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #000000;
}

.username {
  font-weight: bold;
  color: #000000;
}

.wallet-container {
  margin-bottom: 0px;
  padding: 15px;
  border: 0px solid #ccc;
  border-radius: 0 0 25px 25px; /* 只保留下半圆 */
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 15px;
  z-index: 1;
}

/* 最外 */

.character-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: transparent;
  padding: 15px;
  z-index: 1;  
  user-select:none
}

/* 大框 */
.character-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: scroll; /* 只允许垂直滚动 */
  overflow-x: hidden; /* 禁止水平滚动 */
  padding: 0vh;
  margin: 0;
  font-weight: bold;
  height: 100%;
}

.character-background {
  height: 100vh;
  border-radius: 25px;
  box-shadow: 5px 5px 0px rgba(255, 255, 255, 0.5);
  overflow-y: hidden; /* 只允许垂直滚动 */
  overflow-x: hidden; /* 禁止水平滚动 */
  position: relative;
  padding: 0vh;
  z-index: 1;
  border: 2px solid #000000;
  background-color: #79537e;
}

.character-background::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10%, -90%);
  width: 180%;
  height: 120%;
  background: linear-gradient(45deg, #a878ad, #79537e, #79537e, #7affad);
  border-radius: 50%;
  z-index: -1;
  border: #000 2px solid;
  box-shadow: 5px 5px 0px rgb(255, 255, 255, 0.5);
  
}

.character-background::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -10%);
  width: 180%;
  height: 120%;
  background: linear-gradient(45deg, #d06bff, #79537e, #79537e, #7a7e53);
  border-radius: 50%;
  z-index: -1;
  border: #000 2px solid;
  box-shadow: 0px 5px 5px  rgb(255, 255, 255, 0.5);

}
/* 角色卡 */


.character-selection {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding: 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 0%;
  scrollbar-width: 5px;
}

.character-card {
  flex: 0 0 auto;
  width: 75vw; /* 调整卡片宽度 */
  margin: 0 0 0 5vw;
  padding: 3vw;
  border-radius: 10px;
  cursor: pointer; 
  transition: border-color 0.3s;
  position: relative;
  backdrop-filter: blur(3vw);
  border: 0.5vw solid #000000;
  scroll-snap-align: center;
}
.GetNFT-card{
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 70vw; /* 调整卡片宽度 */
  margin: 0 5vw 0 5vw;
  padding: 0;
  cursor: pointer; 
  scroll-snap-align: center;
  justify-items: center;
  align-items: center;
}
.GetNFT-Gif{
  margin-top: 5vh;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
}

/* 为第一个和最后一个卡片添加额外的边距 */
.character-card:first-child {
  margin-left: calc(50% - 40%);
}

.character-card:last-child {
  margin-right: calc(50% - 40%);
}

/* 确保容器有足够的宽度来显示所有卡片 */
.character-selection::after {
  content: '';
  padding-right: 1px;
}

.character-card.selected {
  border-color: #ffffff;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.7);
}

.character-card:last-child {
  margin-right: 100px;
}

.character-selection {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.character-card.unavailable {
  opacity: 0.5;
}
.character-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.5vw solid #000000;
  height: 35vh;
}

.character-image {
  display: flex;
  flex-direction: column;
  background-color: #0000006b;
  width: 50%;
  height: 100%;  
  align-items: center;
  justify-content: center;
  }

.character-image img {
  width: 30vw;
  height: 30vw;
}

.character-skin-container {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
  align-items: center;
  justify-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
}
.character-skin{
  background-color: rgba(255, 255, 255, 0.5);
  width: 17.5vw;
  height: 17.5vw;
  border-radius: 10px;
  border: 0.5vw solid #ffffff;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0.5vw;
}

.character-skin.unavailable{
  opacity: 0.5;
}

.character-skin:hover {

  border: 1vw solid #000000;
}

.character-skin.selected {
  border: 0.5vw solid #ff6d85;
}

.character-skin img {
  width: 10vw;
  height: 10vw;
}

.character-name {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  margin-top: 1vh;
  font-size: 3vw;
  border-radius: 5px;
  box-shadow: 2px 2px 0px rgba(0,0,0,0.2);
  align-self: center;
  }


.locked-overlay {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  z-index: 100;
}

.character-properties {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.stat-bar {
  display: flex;
  gap: 0;
  margin: 0;
  align-items: flex-end; /* 添加这行代码 */
}

.stat-bar-cell {
  flex: 1;
  height: 2.2vh; /* 可以根據需要調整 */
  width: 7vw;
  margin: -0.5vh;
  background-color: lightgray;
  border: 0.7vw solid #000000;
  display: inline-block;
  transform: skewX(-20deg); /* 添加这行代码 */
  align-self: flex-end; /* 添加这行代码 */
  box-shadow: 0.5vw 0.5vw 0px 0px #000000;
  
}

.stat-bar.filled-1 .stat-bar-cell.filled {
  background-color: #b0e2ff;
}

.stat-bar.filled-2 .stat-bar-cell.filled {
  background-color: rgb(209, 255, 136);
}

.stat-bar.filled-3 .stat-bar-cell.filled {
  background-color: rgb(255, 245, 103);
}

.stat-bar.filled-4 .stat-bar-cell.filled {
  background-color: rgb(255, 176, 5);
}

.stat-bar.filled-5 .stat-bar-cell.filled {
  background-color: rgb(255, 0, 0);
}

.stat-bar-cell.filled {
  background-color: green;
}

/* 使用 nth-child 調整每一格的大小 */
.stat-bar-cell:nth-child(1) {
  flex: 1;
}

.stat-bar-cell:nth-child(2) {
  flex: 1.25;
}

.stat-bar-cell:nth-child(3) {
  flex: 1.5;
}

.stat-bar-cell:nth-child(4) {
  flex: 1.75;
}

.stat-bar-cell:nth-child(5) {
  flex: 2;
  height: 4vh;
  border-radius: 0px 1vh 0px 0px;
}

.property-slider {
  display: flex;
  flex-direction: column; /* 添加这行代码 */
  align-items: flex-start; /* 可选：使 label 和 StatBar 左对齐 */
  margin-bottom: 5px; /* 可选：增加间距 */
}

.property-slider label {
  font-size: 2vh;
  font-weight: bold;
  margin: 0.5vh 0 -2vh -1vh;
  text-align: left; /* 文字靠左 */
  z-index:2;
  text-shadow: 
    -2px -2px 0 #fff, 
    2px -2px 0 #fff, 
    -2px 2px 0 #fff, 
    2px 2px 0 #fff,
    -2px 0 0 #fff, 
    2px 0 0 #fff, 
    0 -2px 0 #fff, 
    0 2px 0 #fff; /* 添加更粗的白色文字外框 */
}

.select-button {
  background: linear-gradient(45deg, #96b66c, #94f082, #a0d145, #7affad);
  padding: 1vh 3vh; /* 內邊距 */
  text-align: center; /* 文字居中 */
  text-decoration: none; /* 無下劃線 */
  display: inline-block; /* 行內塊 */
  font-size: 4vh; /* 字體大小 */
  cursor: pointer; /* 鼠標指針 */
  border-radius: 2vw; /* 圓角 */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 背景色和陰影過渡效果 */
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
  font-weight: bold;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
}

.airdrop-button{
 background-color: #ffd900;
 color: #000;
  padding: 1vh 3vh; /* 內邊距 */
  margin: -5vh 0 0 0;
  text-align: center; /* 文字居中 */
  text-decoration: none; /* 無下劃線 */
  display: inline-block; /* 行內塊 */
  font-size: 3vh; /* 字體大小 */
  cursor: pointer; /* 鼠標指針 */
  border-radius: 2vw; /* 圓角 */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 背景色和陰影過渡效果 */
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
  font-weight: bold;
  text-shadow: 0.3vw 0.3vw 0px rgba(0, 0, 0, 0.5);
  border: 0.7vw solid #000000;
}
.airdrop-button:hover {
  transform: translateY(-3px) translateX(-3px);
}

.select-button:hover {
  transform: translateY(-3px) translateX(-3px);
  box-shadow: 6px 8px 0 rgba(255, 255, 255, 0.5), 
              8px 6px 0 rgba(255, 255, 255, 0.3);
            }

.select-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.day-mode .select-button {
  background-color: #ffffff;
  border: 3px solid #000000;
  color: rgb(0, 0, 0);
}

.night-mode .select-button {
  background-color: transparent;
  border: 2px solid #000000;
  color: #ffffff;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  background: linear-gradient(45deg, #20832d, #539c0f);
}

.buying-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  user-select:none
}

.buying-window-content {
  background-color: #333;
  padding: 5vw;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 80vw;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%,-50%);
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skin-image {
  width: 50vw;
  height: 50vw;
  margin: 0 0;
}

.buying-window-content .character-name {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  align-self: center;
}

.buying-window-content p {
  color: white;
  margin: 5px 0;
}

.price {
  font-size: 5vw;
  font-weight: bold;
  color: #ffdd00;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ton-icon {
  font-size: 4vw;
  margin: 0 5px;
  color: #0088CC; /* TON 的蓝色 */
}

.buyWindow-button {
  background-color: #0088CC;
  color: rgb(0, 0, 0);
  border: 2px solid black;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  transition: background-color 0.3s;
  box-shadow: 5px 5px 0px#FFF;
}

.buyWindow-button:hover {
  background-color: #0077B5;
}
.check-transaction-button{
  background-color: #0088CC;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  transition: background-color 0.3s;
}

.check-transaction-button:hover {
  background-color: #0077B5;
}


.Airdrop-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  user-select:none
}

.Airdrop-window-content {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5vw 2vw 5vw 2vw ;
  border-radius: 10px;
  text-align: center;
  position: relative;
  width: 95vw;
  color: #FFF;
}
.Airdrop-content {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2vw;
  border-radius: 10px;
  text-align: center;
  position: relative;
  color: #000000;
  margin: 1vw;
}
.Airdrop-item {
  display: flex;
  justify-content: space-between;
}

.Total{
  font-size: 6vw;
  font-weight: 500;
  
}

.Airdrop-close-button{
  background-color: rgb(255, 208, 0);
  color: rgb(0, 0, 0);
  border: 1vw solid black;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  transition: background-color 0.3s;
  box-shadow: 5px 5px 0px#FFF;
}
.Airdrop-close-button:hover {
  transform: translateY(-3px) translateX(-3px);
}


.debug-info{
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 5000;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 5px;
}