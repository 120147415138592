.background {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease-in-out;
}

.background-day {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(15deg, #fad179,#c3f9fd, #6dd9ec, #5abdff, #00aeff);
  transition: opacity 0.5s ease-in-out;
}

.background-night {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(25deg, #381c46, #2a1d5a, #153063, #041f47);
  transition: opacity 0.5s ease-in-out;
}
.DayBG {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 5;
  }
.DotLottie {
z-index: 5;
}