.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    user-select: none;
    pointer-events: none;
  }
  
  .fire-effect {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .progress-container {
    position: fixed;
    bottom: 20vh ;
    width: 100%;
    padding: 0;

  }
  
  
  .progress-bar-container {
    width: 100%;
  } 
  
.main-content {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
.combos-container{
    display: flex;
    position: absolute;
    top: 22vh;
    z-index: 100;
    color: rgb(255, 255, 255);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items:center;
    transition: opacity 0.1s ease-in-out;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.8);
  }
  .combos-text {
    font-size: 2vh;
    font-weight: normal;
    text-transform: uppercase;
    z-index: 10;

  }
  
  .combos-value {
    font-size: 8vh;
    font-weight: bold;
    line-height: 1;
    position: relative;
    z-index: 500;
  } 
  
  
  .main-points-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    width: auto; 
    width: fit-content;
    position: relative; /* 确保父元素是相对定位 */
  }
  
  .main-points-text {
    font-size: 2vh;
    margin-bottom: 0.5vh;
  }
  
  .main-points-value {
    font-size: 5vh;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .main-catz {
    width: 6vh;
    height: 6vh;
    filter: drop-shadow(0.5vh 0.5vh 0px rgba(0, 0, 0, 0.5));
  }
  .pending-points {
    position: absolute;
    top: 2vw; /* 调整位置 */
    right: 10vw;
    transform: translate(0,0);
    color: rgb(113, 255, 113);
    font-size: 3vh;
    font-weight: bold;
    z-index: 100;
    max-width: 100%; /* 确保不会超出父元素边界 */
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 使用省略号表示超出部分 */
  }
  
  .points-animation {
    position: absolute;
    top: 2vw; /* 调整位置 */
    right: 10vw;
    transform: translate(0,0);
    color: rgb(113, 255, 113);
    font-size: 3vh;
    font-weight: bold;
    z-index: 100;
    max-width: 100%; /* 确保不会超出父元素边界 */
    white-space: nowrap; /* 防止文本换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 使用省略号表示超出部分 */
    filter: opacity(0%)
  }
  
  
  .max-combos-container {
    bottom: 0px; /* 調整這個值來將 Max Combo 移到更底部 */
    z-index: 100;
    color: rgb(255, 255, 255);
    font-size: 1.8vh;
    align-items:baseline;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    justify-content: baseline;
  }
  
  .max-combos-label {
    margin-right: 5px; /* 調整 MAX 和數字之間的間距 */
  }
  
.fire-effect img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
  }  
  
.fire-effect {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.fire-effect img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}
.countdown-timer{
  font-size: 1.5vh;
  color: #ffffff;
}
