root {
    color: rgb(255, 145, 0);
    background-color: rgb(255, 0, 179);

}

body {
    margin: 0;
    color: rgb(229, 255, 0);
    background-color: black;
  }