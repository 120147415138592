.f-message {
  position: fixed;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffcc00;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #000;
  z-index: 1000;
  width: 80%;
  text-align: center;
}
.share-story-button {
  position: absolute;
  top: 0;
  left: 0%;
  transform: translateX(-0%);
  z-index: 0;
  border-radius: 10px;
  padding: 10px;
  background-color: transparent;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}
.share-story-button:hover{

  background-color: #F26B76;
}
.friend-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: transparent;
  padding: 15px;
  z-index: 1;
  user-select:none;
  height: 100%;
  
}

.day-mode .friend-item {
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 1);
}

.friend-list-container {
  margin: 10 auto;
  padding: 3vw 3vw 15vw 3vw; 
  height: 70vh; /* 設置最大高度 */
  overflow-y: scroll; /* 只允許垂直滾動 */
  overflow-x: hidden; /* 禁止水平滾動 */
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: #ffffff;
  scrollbar-width: none;
  justify-content: center;
  align-items: center;
}

.friend-list-background {
  margin: 0;
  padding: 0;
  border-radius: 25px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  height: 70vh; /* 設置最大高度 */
  overflow-y: hidden; /* 只允許垂直滾動 */
  overflow-x: hidden; /* 禁止水平滾動 */
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5); /* 為文字添加陰影 */
  background-color: #F26B76;
  color: #ffffff;
  border: #000000 2px solid;
}


.friend-list-background::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10%, -10%);
  width: 180%;
  height: 120%;
  background: linear-gradient(45deg, #FF6B6B, #FF6B6B, #45B7D1, #FFA07A);
  border-radius: 50%;
  z-index: -1;
  border: #000 2px solid;
  box-shadow: 5px 5px 0px rgb(255, 255, 255, 0.5);

}

.friend-list-background::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -15%);
  width: 180%;
  height: 120%;
  background: linear-gradient(45deg, #ffe96b, #45B7D1, #FF6B6B, #FFA07A);
  border-radius: 50%;
  z-index: -1;
  border: #000 2px solid;
  box-shadow: 0px 5px 5px  rgb(255, 255, 255, 0.5);

}

.team-backgroud{
  position: relative;
  width: 85vw;
  height: 35vw; /* 根据需要调整高度 */
}

.team-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.team-item {
  list-style-type: none;
  position: absolute;
  transform: translate(-50%,0%);
  bottom: 0%;
  width: 100px; /* 默认图标大小 */
  height: 100px;
  display: flex;
}

.team-item img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80px; /* 默认图标大小 */
  height: 80px;
}


.team-item:nth-child(1) {  
  left: 50%;
  z-index: 4; /* 确保玩家图标在最上层 */  
}


.team-item:nth-child(2) {
  left: 40%;
  z-index: 3;
}

.team-item:nth-child(3) {
  left: 60%;
  z-index: 3;
}

.team-item:nth-child(4){
  left: 30%;
  z-index: 2;
}

.team-item:nth-child(5){
  left: 70%;
  z-index: 2;
}
.team-item:nth-child(6){
  left: 20%;
  z-index: 1;
}
.team-item:nth-child(7){
  left: 80%;
  z-index: 1;
}

.team-item:nth-child(1) img {  
  width: 30vw; /* 玩家图标较大 */
  height: 30vw;
}
.team-item:nth-child(2) img {  
  width: 25vw; /* 玩家图标较大 */
  height: 25vw;
}
.team-item:nth-child(3) img {  
  width: 25vw; /* 玩家图标较大 */
  height: 25vw;
}
.team-item:nth-child(4) img {  
  width: 20vw; /* 玩家图标较大 */
  height: 20vw;
}
.team-item:nth-child(5) img {  
  width: 20vw; /* 玩家图标较大 */
  height: 20vw;
}
.team-item:nth-child(6) img {  
  width: 15vw; /* 玩家图标较大 */
  height: 15vw;
}
.team-item:nth-child(7) img {  
  width: 15vw; /* 玩家图标较大 */
  height: 15vw;
}




.friend-list {
  list-style-type: none;
  padding: 0;
}

.friend-item {
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 1.5vh 2vw 1.5vh 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.7);

  border: 2px solid #000000;
  transition: all 0.3s ease;
  backdrop-filter: blur(7px);

}


.friend-item:hover {
  transform: translateY(-3px) translateX(-3px);
  box-shadow: 6px 8px 0 rgba(0, 0, 0, 0.5),
    8px 6px 0 rgba(0, 0, 0, 0.3);
}

.friend-avatar {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.friend-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1vh;
  flex-grow: 1;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0);
}

.friend-details {
  display: flex;
  justify-content: center;
  font-size: 2.5vw;
  color: #000000;
  width: 100%;  
}

.friend-details-item {
  margin-left: 5px; /* Added this line to align points to the right */
  margin-right: 5px;
}

.friend-name {
  font-weight: bold;
  font-size: 3.5vw; /* Adjusted font size */
  color: #000;
}

.invite-container {
  position: fixed;
  bottom: 15vh;
  left: 0;
  right: 0;
  white-space: nowrap; /* 防止按鈕文字換行 */
  overflow: hidden; /* 隱藏溢出內容 */
  text-overflow: ellipsis; /* 文字溢出時顯示省略號 */
  max-width: 100%; /* 確保按鈕不會超出容器寬度 */
  z-index: 10; /* 確保它在其他元素之上 */
}

.day-mode .invite-button {
  background-color: #fff8da;
  border: 3px solid #000000;
  color: rgb(0, 0, 0);
}

.night-mode .invite-button {
  background-color: transparent;
  border: 2px solid #000000;
  color: #ffffff;
  box-shadow: 5px 5px 0px rgb(255, 255, 255, 0.5);
  background: linear-gradient(45deg, #FF6B6B, #FFA07A);
}
.invite-button {
  padding: 1% 5%; /* 內邊距 */
  text-align: center; /* 文字居中 */
  text-decoration: none; /* 無下劃線 */
  display: inline-block; /* 行內塊 */
  font-size: 30px; /* 字體大小 */
  margin: 10px 5px; /* 外邊距 */
  cursor: pointer; /* 鼠標指針 */
  border-radius: 5px; /* 圓角 */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 背景色和陰影過渡效果 */
  box-shadow: 5px 5px 0px rgb(0, 0, 0);
  font-weight: bold;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
}






.apply-button {
  position: absolute;
  width: 50%;
  bottom: 3vh;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #000000; /* 綠色邊框 */
  color: #ffffff; /* 白色文字 */ 
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 10;
  font-size: 3vh;
  font-weight: bold;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}


.day-mode .invite-button:hover, .day-mode .copy-button:hover {
  background: linear-gradient(45deg, #FF6B6B, #FF6B6B, #45B7D1, #FFA07A);
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 1); /* 懸停時的陰影效果 */
  transform: translateY(-3px) translateX(-3px);
  border: 2px solid #000000;
}

.night-mode .invite-button:hover, .night-mode .copy-button:hover {
  background: linear-gradient(45deg, #FF6B6B, #FF6B6B, #45B7D1, #FFA07A);
  box-shadow: 5px 5px 0px rgba(255, 255, 255, 1); /* 懸停時的陰影效果 */
  transform: translateY(-3px) translateX(-3px);
  border: 2px solid #000000;

}

/* 新增複選框樣式 */
input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  padding: 0px;
}

input[type="checkbox"]:checked {
  background-color: #000;
  border-color: #000;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5),
             2px 2px 0 rgba(0, 0, 0, 0.3);
}

input[type="checkbox"]:checked::after {
  content: '✔';
  display: block;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
}


.story-maker-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
}
.story-container {
  width: 100%;
  height: 100%;
}
.story-background {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffcc00;
}

.story-content {
  position: fixed;
}

.story-user {
  position: fixed;
  bottom: -50vh;
  left: 15vw;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-shadow: 2px 2px 0px rgba(255, 255, 255, 0.5);
  
}

.story-user-name {
  font-size: 3.5vh;
  position: absolute;
  top: 15%;  /* 将名字向下移动 */
  right: 32%;
  border-radius: 10px;
  font-family: 'League Spartan', sans-serif;
  color: #ffffff; /* 添加白色文字 */
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);

}

.story-user img {
  width: 100vh;
  height: 100vh;
}
.story-user-cats {
  position: fixed;
  top: 22vh;
  left: 25%;
  transform: translateX(-50%);
  width: 40%;
  text-align: center;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  color: #ffffff;
  font-family: 'League Spartan', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1vh;
}

.story-user-cats .number {
  font-size: 15vw;
  line-height: 1;
  letter-spacing: -0.05em;
}

.story-user-cats .text {
  font-size: 4vw;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-top: -1vw;
}

.story-user-max-combo {
  position: fixed;
  top: 22vh;
  left: 75%;
  transform: translateX(-50%);
  width: 40%;
  text-align: center;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  color: #ffffff;
  font-family: 'League Spartan', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1vh;

}

.story-user-max-combo .number {
  font-size: 15vw;
  line-height: 1;
  letter-spacing: -0.05em;
}

.story-user-max-combo .text {
  font-size: 4vw;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-top: -1vw;
}

.story-friend-item {
  position: fixed;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    width: 15vh;
  height: 15vh;
}
.story-friend-avatar {
  position: absolute;
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.story-friend-name {
  position: absolute;
  top: 70%;
  font-size: 3vw;
  width: 200px;
  text-shadow: 1px 1px 0px rgb(0, 0, 0);
  font-weight: bold;
  font-family: 'League Spartan', sans-serif;
  color: #ffffff; /* 添加白色文字 */

}


.story-text1{
  position: fixed;
  top: 40vh;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8vw;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-family: 'League Spartan', sans-serif;
  letter-spacing: -0.05em; /* 減小字距 */
  line-height: 0.9; /* 減小行距 */
  color: #ffffff; /* 添加白色文字 */
}

.download-button {
  position: fixed;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.5);
}


.debug-buttons {
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 5000;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.debug-button {
  z-index: 5000;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.story-logo {
  position: fixed;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
  width: 30vw;
  height: 10vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}

.story-subtitle {
  position: fixed;
  top: 15vh; 
  left: 50%;
  transform: translateX(-50%);
  font-family: 'League Spartan', sans-serif;
  font-size: 4vw;
  font-weight: 900;
  color: #ffffff;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.1em;
  text-align: center;
  z-index: 2;
}


