.welcome-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  user-select:none;
}
.welcome-modal-content {
  width: 100%;
  position: relative;
}
.welcome-container {
  color: white;
  padding: 0;
  text-align: center;
  font-size: 4vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.welcome-text {
  font-size: 1.2em;
  margin-bottom: 5vh;
}
.welcome-image {
  width: 50px;
  margin: 2vh;
}
.YouGotAFriend-image {
  pointer-events: none;
  width: 100%;
}

.welcome-pages {
  display: flex;
  transition: transform 0.3s ease-in-out;
}
.welcome-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 100%;
  box-sizing: border-box;
  height: 100vh;
}
.page-indicator {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}
.page-dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
}
.page-dot.active {
  background-color: black;
}
.start-button{
  background-color: #FFDD00;
  color: rgb(0, 0, 0);
  border: 3px solid black;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 1px;
  transition: background-color 0.3s;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 20vh;
  width: 70%; 
}

.start-button:hover, .start-button:focus {
  background-color: rgba(255, 255, 255, 0.8);
}

/* 新增第三頁特定樣式 */
.welcome-page-2 {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.welcome-content {
  z-index: 2;
  text-align: center;
  width: 100%;
}

.welcome-text {
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 40px 0;
  font-size: 1.5em;
}

.telegram-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: text-bottom;
  margin-right: 0.1em;
  color: #0088cc;
}

.animation-container {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  z-index: 1;
}

.inviter{
  font-size: 4vh;
position: absolute;
top: 40vh;
width: 100%; 
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

