:root {
  --base-font-size: 4vw;
  --content-width: 95vw;
  --spacing-unit: 2vw;
}
.main-container{
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-unit);
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100vw;
  user-select:none;
}

.day-mode .main-container {
  color: black;
}
.night-mode .main-container {
  color: rgb(255, 255, 255);
}

.user-info {
  width: 100%;
  text-align: center;
  margin-bottom: calc(var(--spacing-unit) * 0);
}

.user-greeting {
  font-size: calc(var(--base-font-size) * 1.2);
  font-weight: 400;
  text-align: center;

}

.main-content {
  width: var(--content-width);
  padding: 0;
  margin: 0;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main-cat-image {
  width: calc(var(--content-width) * 0.2);
  max-width: 200px;
  height: auto;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.button-wrapper {
  position: absolute;
  transform: translate(0,32vh);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 150;
}

.button-wrapper > div,
.button-wrapper > button {
  display: inline-block !important;
}

.wallet-info {
  margin-top: var(--spacing-unit);
  text-align: center;
  font-size: calc(var(--base-font-size) * 0.8);
  position:absolute;
  z-index: 150;
}

.meow {
  position: fixed;
  font-size: calc(var(--base-font-size) * 1.2);
  font-weight: bold;
  pointer-events: none;
  z-index: 10;
  transform: translate(-50%, -50%);
}

@media (min-width: 600px) {
  :root {
    --base-font-size: 16px;
    --content-width: 540px;
    --spacing-unit: 12px;
  }
}

@media (min-width: 1024px) {
  :root {
    --base-font-size: 18px;
    --content-width: 600px;
    --spacing-unit: 16px;
  }
}


.share-button {
  position: absolute;
  transform: translate(0,6vh);
  z-index: 150;
  background: linear-gradient(45deg, #269af8, #ffb259); /* 漸變色 */
  color: #ffffff;
  border: none;
  margin: 15px;
  padding: 10px 25px;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 15px;
}

.matter-js-scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  pointer-events: auto;  /* 改为 auto 以允许鼠标事件 */
}

.matter-js-scene {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: auto;
}

.main-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 100px; /* 与 bottomBorderHeight 相同 */
  box-sizing: border-box;
  
}

.matter-js-scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.progress-container {
  position: absolute;
  bottom: -60vh;
  z-index: 100;
  color: rgb(255, 255, 255);
  pointer-events: none; 
  text-align: center;
  align-items: center;
  display: flex; /* 新增 */
  flex-direction: column; /* 新增 */
}

.maxReached-container {
  color: rgb(255, 0, 0);
  font-size: calc(var(--base-font-size) * 0.8);
  font-weight: bold;
  pointer-events: none; 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* 新增 */
}

.progress-bar-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 70%;
  display: flex; /* 新增 */
  justify-content: center; /* 新增 */
}

.progress-bar {
  background-color: #76c7c0;
  color: #000;
  transition: width 0.3s ease;
  border-radius: 10px;
}
.progress-bar-text
{
color: #ffffff;
font-size: calc(var(--base-font-size) * 0.8);
}

.sound-toggle-button {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 10px;
  left: 10px;
  z-index: 200;
  background-color: #00000056;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sound-toggle-icon
{
  filter: invert(1);
  height: 15px;
  width: 15px;
}


