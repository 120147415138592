.starter-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.starter-container {
  color: white;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.starter-text {
  font-size: 1.2em;
  margin-bottom: 0vh;
}
.starter-days {
  font-size: 30vh;
  margin: 0;
  padding: 0;
}
.starter-image {
  width: 50px;
  margin: 2vh;
}
.starter-pages {
  display: flex;
  transition: transform 0.3s ease-in-out;
}
.starter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  box-sizing: border-box;
  height: 100vh;  
}
.page-indicator {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}
.page-dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  cursor: pointer;
}
.page-dot.active {
  background-color: black;
}
.dotLottie{  
  width: 30%;
}
.DayToNightToggle{
  margin: 10vh 0 15vh 0;
  width: 100%;
  transform: scale(1.2);
  z-index: 5;
}
.starter-start-button {
  background-color: rgb(255, 208, 0);
  color: rgb(0, 0, 0);
  border: 3px solid black;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 1px;
  transition: background-color 0.3s;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  z-index: 1001;
  margin: 0 0 5vh 0;
}
.starter-start-button:hover, .starter-start-button:focus {
  background-color: rgba(255, 255, 255, 0.8);
}
.fireworks {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}